import React from "react";
import Img from "gatsby-image";
import useGetImage from "./useGetImage";
import s from "./Hero.module.scss";
const Hero = () => {
    const hero = useGetImage();
    return (<section>
      <Img className={s.img} fluid={hero.childImageSharp.fluid}/>
      <h1 className={s.title}>HopTechnic®</h1>
      <p className={s.text}>
        HopTechnic® is a subsidiary of Virgil Gamache Farms, Inc. Created
        several years ago to ensure the quality of hops grown for VGF under the
        Amarillo® brand, HopTechnic® has evolved into a fully functional
        laboratory that performs a wide range of testing.
      </p>
      <span className={s.border}/>
    </section>);
};
export default Hero;
