import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const { hero } = useStaticQuery(graphql `
    query {
      hero: file(relativePath: { eq: "images/hopTechnic/hopTechnicHero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
    return hero;
};
