import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const { decor } = useStaticQuery(graphql `
    query {
      decor: file(relativePath: { eq: "images/hopTechnic/decor.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
    return decor;
};
