import React from "react";
import classnames from "classnames";
import useGetImage from "./useGetImage";
import s from "./Decor.module.scss";
const Decor = ({ reverse }) => {
    const decor = useGetImage();
    const box = classnames(s.box, { [s.reverse]: reverse });
    const img = classnames(s.img, { [s.reverseImg]: reverse });
    return (<div className={box}>
      <img className={img} src={decor.childImageSharp.fluid.src} alt="decor element"/>
    </div>);
};
export default Decor;
