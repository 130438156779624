import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const { img } = useStaticQuery(graphql `
    query {
      img: file(relativePath: { eq: "images/hopTechnic/hopTechnic-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
    return img;
};
