const testPackagesData = [
    {
        listTitle: "Harvest Timing Assessment",
        price: "Inquire Within",
        content: "Field samples are monitored by the lab to help track and determine ideal harvest windows.",
    },
    {
        listTitle: "Aroma Assessment",
        price: "$225/sample",
        content: "Terpene content and aroma assessment for a hop sample",
    },
    {
        listTitle: "*Agricultural Water Testing",
        price: "$25/sample",
        content: "Agricultural water samples may be submitted to the lab and tested for the amount of E. coli and coliforms present, in compliance with FSMA, Global G.A.P. and Green Chief practices. The lab will provide year end feedback regarding microbiological levels in tested water.",
    },
    {
        listTitle: "Comprehensive Hop Assessment",
        price: "$300/sample",
        content: "Features all offered hop tests for a submitted sample.",
    },
    {
        listTitle: "Quantitative Hop Assessment",
        price: "$80/sample",
        content: "Alpha/beta, total oil, moisture content analysis of hop samples",
    },
];
const SingleHopData = [
    {
        listTitle: "*Alpha/Beta Acids",
        price: "$40",
        content: "Submitted samples are tested for alpha and beta acid content by HPLC",
    },
    {
        listTitle: "*Iso-Alpha Acids",
        price: "$20",
        content: "Upon request, a sample submitted for alpha/beta acids may also be tested for iso-alpha compounds at an additional charge.",
    },
    {
        listTitle: "*Moisture Content/Dry Matter",
        price: "$20",
        content: "Samples are tested for the amount of moisture.",
    },
    {
        listTitle: "Aroma Analysis",
        price: "$125",
        content: "Aroma is evaluated and presented in a spider diagram",
    },
    {
        listTitle: "Total Oil",
        price: "$30",
        content: "A sample is tested for the total amount of oil present.",
    },
    {
        listTitle: "Terpenes Analysis",
        price: "$80",
        content: "Upon request, a submitted sample may be tested for the composition of the hop oil at an additional charge.",
    },
];
export { testPackagesData, SingleHopData };
