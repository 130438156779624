import React from "react";
import Img from "gatsby-image";
import useGetImage from "./useGetImage";
import s from "./Content.module.scss";
const Content = () => {
    const img = useGetImage();
    return (<section className={s.wrap}>
      <div className={s.imgWrap}>
        <Img className={s.img} fluid={img.childImageSharp.fluid}/>
      </div>
      <div className={s.textWrap}>
        <h2 className={s.title}>Common Questions</h2>
        <p className={s.subtitle}>Is HopTechnic® a certified lab?</p>
        <p className={s.text}>
          Yes, HopTechnic® is an ISO/IEC 17025:2017 accredited lab and offers
          several accredited tests as part of our scope.
        </p>
        <p className={s.subtitle}>
          What tests are involved in the Hop Assessments
        </p>
        <p className={s.text}>
          Generally, hop assessments involve analysis of color, moisture
          content, alpha/beta acids, total oil and terpenes as well as aroma
          analysis.
        </p>
        <p className={s.subtitle}>Where/When do I drop off samples?</p>
        <p className={s.text}>
          Samples can be dropped off at or shipped to 6371 Ford Rd Toppenish, WA
          98948. During harvest, we accept samples M-S from 7am to 5pm.
          Off-season samples are accepted M-F from 8am-4pm. If shipping samples,
          please contact us for shipping requirements.
        </p>
        <p className={s.subtitle}>Is HopTechnic® a certified lab?</p>
        <p className={s.text}>
          Yes, HopTechnic® is an ISO/IEC 17025:2017 accredited lab and offers
          several accredited tests as part of our scope.
        </p>
      </div>
      <span className={s.border}/>
    </section>);
};
export default Content;
