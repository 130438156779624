import React from "react";
import s from "./List.module.scss";
const ListItem = ({ listTitle, price, content }) => {
    return (<li className={s.listItem}>
      <div className={s.box}>
        <span className={s.label}>{listTitle}</span>
        <span className={s.decor}/>
        <span className={s.label}>{price}</span>
      </div>
      <div className={s.text}>{content}</div>
    </li>);
};
const List = ({ title, subtitle, listData }) => {
    return (<section className={s.wrap}>
      <h2 className={s.title}>{title}</h2>
      <p className={s.subtitle}>{subtitle}</p>
      <ul className={s.list}>
        {listData.map(({ listTitle, price, content }, i) => {
        return (<ListItem key={i} listTitle={listTitle} price={price} content={content}/>);
    })}
      </ul>
      <span className={s.border}/>
    </section>);
};
export default List;
