import React from "react";
import s from "./HopTechnic.module.scss";
import Hero from "./components/hero";
import Content from "./components/content";
import List from "./components/list";
import Decor from "./components/decor";
import { testPackagesData, SingleHopData } from "./hopTechnicData";
const HopTechnic = () => {
    return (<div className={s.wrap}>
      <Decor />
      <Decor reverse/>
      <Hero />
      <Content />
      <List title="Test Packages" subtitle="*These tests are part of our ISO/IEC 17025:2017 scope" listData={testPackagesData}/>
      <List title="Single Hop Sample Tests" listData={SingleHopData}/>
    </div>);
};
export default HopTechnic;
