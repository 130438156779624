import React from "react";
import HopTechnic from "../scenes/hopTechnic";
import SEO from "../components/seo";
import { keywords } from "../content/keywords";
export default () => {
    return (<>
      <SEO title={"HopTechnic"} keywords={keywords}/>
      <HopTechnic />
    </>);
};
